import { Route, Routes } from "react-router-dom";
import { FC } from "react";
import styles from "./App.module.scss";
import { HomePageEN } from "../../pages";
import { Header, Footer } from "../layout";
import { NotFound } from "../../pages/NotFound/NotFound";

export const App: FC = () => {
  // useEffect(() => {
  //   if (location.pathname === "/") {
  //     navigate(`/${lang}`);
  //   }
  // }, [lang, location.pathname, navigate]);

  return (
    <div className={styles.app}>
   
        <Header />
        <div className={styles.container}>
        <Routes>
          <Route path="/" element={<HomePageEN />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        </div>
        <Footer />
  
    </div>
  );
};

export default App;
