import { FC, useCallback, useEffect, useRef } from "react";
import styles from "./Popup.module.scss";

export type TPopup = {
  isOpen: boolean;
  setPopupOpen: (value: boolean) => void;
  onClick: () => void;
  children: React.ReactNode;
};

export const Popup: FC<TPopup> = ({
  isOpen,
  setPopupOpen,
  children,
}) => {
  const popupRef = useRef<HTMLDivElement>(null);
 
  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(evt.target as HTMLElement)
      ) {
        setPopupOpen(false);
      }
    },
    [setPopupOpen]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
  return (
    <>

      <div className={`${styles.popup} ${isOpen && styles.popup_opened}`}>
        <div className={styles.popup__container} ref={popupRef}>
         {children}
        </div>
      </div>
    </>
  );
};
