import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { TLang } from '../../types/lang'
import englang from '../../images/languages/england.svg'
import france from '../../images/languages/france.svg'
import { getLang } from '../../helpers/getLang'

type TDropdownItemLang = {
  id: number
  lang: string
  abbr: string
  icon: string
}

interface langState {
  lang: TLang
  languages: Array<TDropdownItemLang>
}

const initialState: langState = {
  lang: getLang(),
  languages: [
    { id: 1, lang: 'English', abbr: 'en', icon: englang },
    { id: 2, lang: 'French', abbr: 'fr', icon: france },
  ],
}

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    setLang(state, action: PayloadAction<TLang>) {
      state.lang = action.payload
      localStorage.setItem('lang', action.payload)
    },
  },
})

export const { setLang } = langSlice.actions
