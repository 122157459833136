import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import styles from "./Form.module.scss";
import { Button } from "../ui";
import { dataPopup } from "../Popup/constants";
import { isValidEmail } from "../../helpers/isValidEmail";
import { TStatusNotify } from "../Notify/Notify";

type TForm = {
  title: string;
  placeholder: {
    name: string;
    company: string;
    email: string;
    message: string;
  };
  buttonText: string;
  isPopup?: boolean;
  setOpenPopup?: (open: boolean) => void;
  setOpenNotify: (open: boolean) => void;
  setNotify: Dispatch<
    SetStateAction<{ text: string; title: string; status: TStatusNotify }>
  >;
};

export const Form: FC<TForm> = ({
  title,
  placeholder,
  buttonText,
  setOpenPopup,
  isPopup = false,
  setNotify,
  setOpenNotify
}) => {
  const data = dataPopup["en"];

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });

  const [formDataError, setFormDataError] = useState({
    name: false,
    email: false,
  });

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log("submot");

    if (!formData.name || !formData.email || !isValidEmail(formData.email)) {
      setFormDataError({
        name: !formData.name,
        email: !formData.email || !isValidEmail(formData.email),
      });
    } else {
      setFormDataError({
        name: false,
        email: false,
      });
      try {
        const response = await fetch(
          `${process.env.REACT_APP_URL_BACKEND}/send-email-from-india`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              name: formData.name,
              companyName: formData.company,
              email: formData.email,
              msg: formData.message,
              subText: "Main page",
            }),
          }
        );
        const result = await response.json();
        if (result.success) {
          //@ts-ignore
          setNotify(data.success);
          setOpenNotify(true);
          setFormData({
            name: "",
            email: "",
            company: "",
            message: "",
          });
        } else {
          throw new Error(result.error);
        }
      } catch (error) {
        //@ts-ignore
        setNotify(data.error);
        setOpenNotify(true);
      }
      setFormDataError({
        name: false,
        email: false,
      });
      setOpenPopup && setOpenPopup(false);
    }
  };

  useEffect(() => {
    if (formData.name && formDataError.name) {
      setFormDataError({
        ...formDataError,
        name: false,
      });
    }
    if (formData.email && formDataError.email) {
      setFormDataError({
        ...formDataError,
        email: false,
      });
    }
  }, [formData.email, formData.name, formDataError]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <form
      className={`${styles.form} ${isPopup && styles.popup}`}
      onSubmit={handleSubmit}
    >
      <h2 className={styles.title}>{title}</h2>
      <label className={styles.label}>
        <div>
          <input
            type="text"
            name="name"
            placeholder={placeholder.name}
            className={`${styles.label__input} ${
              formDataError.name && styles.error
            }`}
            onChange={handleChange}
          />
          {formDataError.name && (
            <span className={styles.errorText}>{data.errorText}</span>
          )}
        </div>
        <input
          type="text"
          name="company"
          onChange={handleChange}
          placeholder={placeholder.company}
          className={`${styles.label__input}`}
        />
        <div>
          <input
            type="text"
            name="email"
            onChange={handleChange}
            placeholder={placeholder.email}
            className={`${styles.label__input} ${
              formDataError.email && styles.error
            }`}
          />
          {formDataError.email && (
            <span className={styles.errorText}>Invalid email</span>
          )}
        </div>
        <textarea
          onChange={handleChange}
          name="message"
          placeholder={placeholder.message}
          className={styles.label__message}
        ></textarea>
      </label>
      <div className={styles.btn}>
        <Button children={buttonText} type="submit" />
      </div>
    </form>
  );
};
