import { LANG } from "../../types/lang";
// ---- Our products -----
import ewallet from "../../images/our_products/ewallet.jpg";
import banking from "../../images/our_products/banking.jpg";
import direct from "../../images/our_products/direct.jpg";
import gateway from "../../images/our_products/gateway.jpg";

// ---- Why Choose Mandato? -----
import security from "../../images/why_choose/security.svg";
import payment from "../../images/why_choose/payment.svg";
import support from "../../images/why_choose/support.svg";
import speed from "../../images/why_choose/speed.svg";
import thumb from "../../images/why_choose/thumb.svg";

// ---- Industries We Work With ----
import ecommerce from "../../images/industries/eCommerce.svg";
import game from "../../images/industries/Game.svg";
import casino from "../../images/industries/Casino.svg";
import forex from "../../images/industries/Fornex.svg";
import adult from "../../images/industries/Adult.svg";
import streaming from "../../images/industries/Streaming.svg";

// ---- 100+ Payment Options ----
import visa from "../../images/payments/visa.svg";
import mastercard from "../../images/payments/mastercard.svg";
import paypal from "../../images/payments/paypal.svg";
import amazon_pay from "../../images/payments/amazon_pay.svg";
import american_express from "../../images/payments/american_express.svg";
import apple_pay from "../../images/payments/apple_pay.svg";
import axis_bank from "../../images/payments/axis_bank.svg";
import g_pay from "../../images/payments/g_pay.svg";
import hdfc from "../../images/payments/hdfc.svg";
import icici from "../../images/payments/icici.svg";
import imps from "../../images/payments/imps.svg";
import mobikwick from "../../images/payments/mobikwick.svg";
import paytm from "../../images/payments/paytm.svg";
import phone_pe from "../../images/payments/phone-pe.svg";
import sbi from "../../images/payments/sbi.svg";
import upi from "../../images/payments/upi.svg";

// ---- Our Contacts ----
import url from "../../images/contacts/url.svg";
import location from "../../images/contacts/location.svg";
import mail from "../../images/contacts/mail.svg";

export const content = {
  [LANG.EN]: {
    started: {
      buttonText: 'Get started for free',
      block: 'Transforming Payments in India with Swiss Expertise',
      description: 'Empower your business with our complete payment solutions, designed to meet the unique needs of India`s industries.',
      header: 'Top Payment Merchant Solutions Across India'
    },
    about_us: { 
      header: 'About Us',
      text_1: 'Founded in 2006 in Zurich, Switzerland, Mandato Financial Services GmbH delivers Swiss precision in digital payments.',
      text_2: 'Our commitment is to advance digital finance with innovative technology and a focus on security and customer satisfaction.',
      title: 'We provide:',
      list: [
        'Secure payment solutions for high-risk industries',
        'Multi-currency processing for seamless transactions',
        'Compliance with global regulatory standards',
      ]
    },
    our_products: {
      title: "Our Products",
      textButton: "Get started for free",
      products: [
        {
          name: "Gateway",
          img: gateway,
          features: [
            {
              title: "Global Reach",
              text: "Accept payments worldwide in multiple currencies.",
            },
            {
              title: "Security First",
              text: "PCI DSS compliant with advanced fraud protection.",
            },
            {
              title: "Real-Time Insights",
              text: "Track transactions and revenue with ease.",
            },
            {
              title: "Custom Checkout",
              text: "Tailor your payment page to your brand.",
            },
            {
              title: "Reliable Performance",
              text: "High availability and fast processing speeds.",
            },
          ],
        },
        {
          name: "Banking",
          img: banking,
          features: [
            {
              title: "Global Accessibility",
              text: "Open IBAN accounts for seamless international payments.",
            },
            {
              title: "Secure Transactions",
              text: "Advanced KYC and AML for top-level security.",
            },
            {
              title: "Effortless Payments",
              text: "Manage payments in multiple fiat currencies.",
            },
            {
              title: "Fintech Support",
              text: "Specialized solutions for fintech transaction needs.",
            },
            {
              title: "User-Friendly Platform",
              text: "Control your finances from anywhere.",
            },
            {
              title: "FX Expertise",
              text: "Reduce currency fluctuation with tailored solutions.",
            },
          ],
        },
        {
          name: "Direct",
          img: direct,
          features: [
            {
              title: "Simple and Convenient",
              text: "Pay directly from your bank account.",
            },
            {
              title: "Enhanced Privacy",
              text: "No card numbers; no data stored.",
            },
            {
              title: "Instant Verification",
              text: "Real-time confirmation to reduce defaults.",
            },
            {
              title: "Boost Customer Loyalty",
              text: "Quick and reliable payments.",
            },
            {
              title: "Unmatched Security",
              text: "PCI DSS compliant with one-time TANs.",
            },
          ],
        },
        {
          name: "eWallet",
          img: ewallet,
          features: [
            {
              title: "All-in-One Solution",
              text: "Deposit, spend, and transfer money easily.",
            },
            {
              title: "Link Your IBAN",
              text: "Seamless and secure transactions.",
            },
            {
              title: "On-the-Go Management",
              text: "Manage finances with our mobile platform.",
            },
            {
              title: "Track Spending",
              text: "Monitor transactions with detailed insights.",
            },
            {
              title: "Top-Tier Security",
              text: "Advanced features to protect your funds.",
            },
            {
              title: "User-Friendly Interface",
              text: "Intuitive and easy financial management.",
            },
          ],
        },
      ],
    },
    why_choose_us: {
      title: "Why Choose Mandato?",
      features: [
        {
          img: security,
          text: "Highest level of security with PCI-DSS Level 1 certification",
        },
        {
          img: payment,
          text: "Reliable and diverse payment solutions for all industries",
        },
        {
          img: support,
          text: "24/7 dedicated customer support",
        },
        {
          img: speed,
          text: "Fast and seamless onboarding process",
        },
        {
          img: thumb,
          text: "Flexible integration with popular platforms",
        },
      ],
    },
    industries: {
      title: "Industries We Work With",
      subtitle: "...and others!",
      industries: [
        {
          img: ecommerce,
          name: "eCommerce",
        },
        {
          img: game,
          name: "Gaming",
        },
        {
          img: casino,
          name: "Casino",
        },
        {
          img: forex,
          name: "Forex",
        },
        {
          img: adult,
          name: "Adult",
        },
        {
          img: streaming,
          name: "Streaming",
        },
      ],
    },
    payments: {
      title: "100+ Payment Options",
      subtitle:
        "Our platform supports a diverse array of methods, ensuring seamless transactions for your business.",
      options: [
        visa,
        mastercard,
        g_pay,
        apple_pay,
        paypal,
        amazon_pay,
        american_express,
        paytm,
        upi,
        imps,
        sbi,
        phone_pe,
        mobikwick,
        axis_bank,
        icici,
        hdfc,
      ],
    },
    contacts: {
      title: "Our Contacts",
      form: {
        title: 'Get In Touch',
        buttonText: 'Submit',
        placeholder: {
          name: 'Your Name*',
          email: 'Email*',
          company: 'Company Name',
          message: 'Message',
        }
      },
      contacts: {
        site: {
          icon: url,
          url: "www.mandato.global",
        },
        mail: {
          icon: mail,
          emails: ["d.a@mandato.global", "operations@mandato.global"],
        },
        location: {
          icon: location,
          address: `Mandato Financial Services GmbH\nZugerstrasse 46\n6314 Unterägeri\nSwitzerland`,
        },
      },
    },
  },
};
