/* eslint-disable jsx-a11y/iframe-has-title */
import { FC, useState } from "react";
import React from "react";
import { LANG } from "../../types/lang";
import { content } from "./constants";
import { Started } from "../../components/ui/Started/Started";
import { AboutUs } from "../../components/ui/AboutUs/AboutUs";
import styles from "./HomePage.module.scss";
import { Product } from "../../components/Product/Product";
import { Button, Popup } from "../../components";
import { Feature } from "../../components/Feature/Feature";
import { Industry } from "../../components/Industry/Industry";
import { Contacts } from "../../components/Contacts/Contacts";
import { Form } from "../../components/Form/Form";
import useResize from "../../helpers/useResize";
import { Notify, TStatusNotify } from "../../components/Notify/Notify";
import Swiper from "react-id-swiper";
export const HomePageEN: FC = () => {
  const [open, setOpen] = useState(false);
  const LANGUAGE = LANG.EN;
  const { width } = useResize();
  const params = {
    spaceBetween: 8
  };
  const [openNotify, setOpenNotify] = useState<boolean>(false);
  const [notify, setNotify] = useState<{
    text: string;
    title: string;
    status: TStatusNotify;
  }>({
    text: "",
    title: "",
    status: "default",
  });

  const handleClick = () => {
    setOpen(true);
  };
  return (
    <>
      <Notify
        status={notify.status}
        text={notify.text}
        title={notify.title}
        open={openNotify}
        setOpen={setOpenNotify}
      />
      <Started
        onClick={handleClick}
        buttonText={content[LANGUAGE].started.buttonText}
        block={content[LANGUAGE].started.block}
        header={content[LANGUAGE].started.header}
        description={content[LANGUAGE].started.description}
      />

      <section className={styles.about_us} id="about-us">
        <AboutUs
          header={content[LANGUAGE].about_us.header}
          text_1={content[LANGUAGE].about_us.text_1}
          text_2={content[LANGUAGE].about_us.text_2}
          title={content[LANGUAGE].about_us.title}
          list={content[LANGUAGE].about_us.list}
        />
      </section>

      <section className={`section ${styles.slider_container} ${styles.wrapper}`} id="our-products">
        <h2 className={styles.title}>{content[LANGUAGE].our_products.title}</h2>
        <ul className={styles.products}>
          {content[LANGUAGE].our_products.products.map((product) => (
            <li key={product.name}>
              <Product {...product} />
            </li>
          ))}
        </ul>
        <div className={styles.slider}>
          <Swiper {...params}>
            {content[LANGUAGE].our_products.products.map((product) => (
              <div key={product.name} >
              <Product {...product} />
              </div>
            ))}
          </Swiper>
        </div>
        <div className={styles.button}>
          <Button onClick={handleClick}>
            {content[LANGUAGE].our_products.textButton}
          </Button>
        </div>
      </section>

      <div className={styles.why} id="why-choose-us">
        <section className={`section ${styles.wrapper}`}>
          <h2 className={`${styles.title} ${styles.white}`}>
            {content[LANGUAGE].why_choose_us.title}
          </h2>
          <ul className={styles.features}>
            {content[LANGUAGE].why_choose_us.features.map((feature) => (
              <Feature {...feature} key={feature.text} />
            ))}
          </ul>
        </section>
      </div>
      <section className={`section ${styles.wrapper}`} id="industries">
        <h2 className={styles.title}>{content[LANGUAGE].industries.title}</h2>
        <ul className={styles.industries}>
          {content[LANGUAGE].industries.industries.map((industry) => (
            <Industry {...industry} />
          ))}
        </ul>
        <p className={styles.subtitle}>
          {content[LANGUAGE].industries.subtitle}
        </p>
      </section>

      <div className={styles.payments}>
        <section className={`section ${styles.wrapper}`}>
          <h2 className={styles.title}>{content[LANGUAGE].payments.title}</h2>
          <p className={styles.subtitle}>
            {content[LANGUAGE].payments.subtitle}
          </p>
          <ul className={styles.options}>
            {content[LANGUAGE].payments.options.map((option) => (
              <img src={option} alt="payment option" />
            ))}
          </ul>
        </section>
      </div>

      <section
        className={`${styles.contacts} section ${styles.wrapper} `}
        id="contacts"
      >
        <Form
          setNotify={setNotify}
          setOpenNotify={setOpenNotify}
          title={content[LANGUAGE].contacts.form.title}
          placeholder={{
            name: content[LANGUAGE].contacts.form.placeholder.name,
            company: content[LANGUAGE].contacts.form.placeholder.company,
            email: content[LANGUAGE].contacts.form.placeholder.email,
            message: content[LANGUAGE].contacts.form.placeholder.message,
          }}
          buttonText={content[LANGUAGE].contacts.form.buttonText}
        />
        <div className={styles.info}>
          <h2 className={styles.title}>{content[LANGUAGE].contacts.title}</h2>
          <div className={styles.info__content}>
            <Contacts {...content[LANGUAGE].contacts.contacts} />
            <div className={styles.block}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2714.0015698070456!2d8.577267299999999!3d47.1382353!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479aaca583477ee3%3A0x7b6c393121d272c4!2zWnVnZXJzdHJhc3NlIDQ2LCA2MzE0IFVudGVyw6RnZXJpLCDQqNCy0LXQudGG0LDRgNC40Y8!5e0!3m2!1sru!2sru!4v1728332297841!5m2!1sru!2sru"
                height={
                  width < 1200
                    ? width < 839
                      ? width < 599
                        ? "182px"
                        : "264px"
                      : "240px"
                    : "100%"
                }
                width="100%"
                // style={{"border:0"}}
                allowFullScreen={false}
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </section>

      {open && (
        <Popup isOpen={open} setPopupOpen={setOpen} onClick={() => {}}>
          <Form
            setNotify={setNotify}
            setOpenNotify={setOpenNotify}
            setOpenPopup={setOpen}
            title={content[LANGUAGE].contacts.form.title}
            placeholder={{
              name: content[LANGUAGE].contacts.form.placeholder.name,
              company: content[LANGUAGE].contacts.form.placeholder.company,
              email: content[LANGUAGE].contacts.form.placeholder.email,
              message: content[LANGUAGE].contacts.form.placeholder.message,
            }}
            isPopup={true}
            buttonText={content[LANGUAGE].contacts.form.buttonText}
          />
        </Popup>
      )}
    </>
  );
};
