import { FC } from "react";
import styles from "./Industry.module.scss";

type TIndustry = {
  img: string;
  name: string;
};

export const Industry: FC<TIndustry> = ({ img, name }) => {
  return (
    <li className={styles.industry} key={name}>
      <img src={img} alt={name} className={styles.img} />
      <p className={styles.text}>{name}</p>
    </li>
  );
};
