import { FC } from 'react'
import styles from './AboutUs.module.scss'
import people from '../../../images/aboutUs/aboutUs.jpg'
import check from '../../../images/aboutUs/Check.svg'

type TAboutUs = {
  header: string
  text_1: string
  text_2: string
  title: string
  list: Array<string>
}

export const AboutUs: FC<TAboutUs> = ({header, text_1, text_2, title, list}) => {
  return (
    <section className={styles.aboutUs}>
      <h2 className={styles.aboutUs__header}>{header}</h2>
      <div className={styles.aboutUs_block}>
      <div className={styles.aboutUs__description}>
        <p className={styles.aboutUs__text}>
          {text_1}
        </p>
        <p className={styles.aboutUs__description_head}>{title}</p>
        <div className={styles.list}>
          {list.map((item) => (
            <div className={styles.list_item} key={item}>
              <img src={check} alt='' className={styles.list_icon} />
              <p className={styles.aboutUs__text}>{item}</p>
            </div>
          ))}
        </div>
        <p className={styles.aboutUs__text}>
          {text_2}
        </p>
      </div>
      <img src={people} alt='' className={styles.aboutUs__image} />
      </div>
    </section>
  )
}
