import { FC } from "react";
import styles from "./Feature.module.scss";

type TFeature = {
  img: string;
  text: string;
};

export const Feature: FC<TFeature> = ({ img, text }) => {
  return (
    <li className={styles.feature} key={text}>
      <img src={img} alt={text} className={styles.img} />
      <p className={styles.text}>{text}</p>
    </li>
  );
};
