import { FC, useState } from "react";
import styles from "./Header.module.scss";
import logo from "../../../images/logo.svg";
import { routesEN } from "./constant";

export const Header: FC = () => {
  const [routes, setRoutes] = useState(routesEN);
  const [openMenu, setOpenMenu] = useState(false)

  // useEffect(() => {
  //   if (lang === LANG.FR) {
  //     setRoutes(routesFR);
  //   } else {
  //     setRoutes(routesEN);
  //   }
  // }, [lang]);

  return (
    <>
    <div className={styles.header}>
      <nav className={styles.nav}>
        <img className={styles.logo} src={logo} alt="Логотип" />
        <ul className={styles.routes}>
          {routes.map((item, index) => (
            <li className={styles.routes_item} key={index}>
              <a href={'#'+item.path} className={styles.link}>
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        <input
              type="checkbox"
              id="burger-checkbox"
              className={styles.burgerСheckbox}
              checked={openMenu}
              onClick={() => setOpenMenu(!openMenu)}
            />
            <label className={styles.burger} htmlFor="burger-checkbox"></label>

        </nav>
    </div>
     <ul className={`${styles.menu_routes} ${openMenu && styles.open}`}>
     {routes.map((item, index) => (
       <li className={styles.menu_routes_item} key={index}>
         <a href={'#'+item.path} className={styles.menu_link} onClick={() => setOpenMenu(false)}>
           {item.name}
         </a>
       </li>
     ))}
   </ul>
   </>
  );
};

