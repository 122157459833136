import { FC } from 'react'
import styles from './Stardet.module.scss'
import swit from '../../../images/started/switzerland_flag.svg'
import { Button } from '../Button/Button'

type TStarted = {
  buttonText: string
  block: string
  header: string
  description: string
  onClick: () => void;
}

export const Started: FC<TStarted> = ({buttonText, block, header, description, onClick}) => {
  return (
    <section className={styles.started}>
      <div className="section">
      <div className={styles.info}>
        <div className={styles.info__whiteText}>
          <img src={swit} alt='' className={styles.info__whiteText_icon} />
          <p className={styles.info__whiteText_txt}>
            {block}
          </p>
        </div>
        <h1 className={styles.info_header}>
          {header}
        </h1>
        <h5 className={styles.info_description}>
          {description}
        </h5>
        <div className={styles.button}>
          <Button children={buttonText} onClick={onClick}/>
        </div>
      </div>
      </div>
    </section>
  )
}
