import { FC } from "react";
import styles from "./Product.module.scss";

type TProduct = {
  img: string;
  name: string;
  features: {
    title: string;
    text: string;
  }[];
};

export const Product: FC<TProduct> = ({ img, name, features }) => {
  return (
    <div>
      <img src={img} alt={name} className={styles.img}/>
      <h4 className={styles.title}>{name}</h4>
      <ul className={styles.list}>
        {features.map(({ title, text }) => (
          <li key={title}>
            <span className="bold">{title}: </span>
            {text}
          </li>
        ))}
      </ul>
    </div>
  );
};
