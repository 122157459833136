import { FC, useEffect, useState } from "react";
import styles from "./Footer.module.scss";
import logo from "../../../images/logo.svg";
import psi from "../../../images/footer/pci.svg";
import switzerland from "../../../images/started/switzerland_flag.svg";
import { useAppSelector } from "../../../services/hooks";
import { dataFooter } from "./constants";

export const Footer: FC = () => {
  const lang = useAppSelector((state) => state.lang.lang);
  const [content, setContent] = useState(dataFooter[lang]);

  useEffect(() => {
    setContent(dataFooter[lang]);
  }, [lang]);
  return (
    <div className={styles.container}>
      <div className={styles.line}>
        <div className={styles.marquee}>
          <img
            src={switzerland}
            alt="switzerland"
            className={styles.line__logo}
          />
          {content.line}
        </div>
      </div>

      <footer className={`${styles.wrapper} ${styles.footer}`}>
        <div className={styles.icon}>
        <img src={logo} alt="Logo" className={styles.logo} />
        <ul className={styles.social}>
          {content.social.map(({ name, link, icon }) => (
            <li key={name}>
              <a
                target="_blank"
                href={link}
                className={styles.social__item}
                rel="noreferrer"
              >
                <img src={icon} alt={name} />
              </a>
            </li>
          ))}
        </ul>
        </div>
        <nav className={styles.blockNav}>
          <ul className={styles.nav}>
            {content.nav.map(({ name, link }, index) => (
              <>
                {index !== 0 ? (
                  <span className={styles.footer__line} key={name+index}>•</span>
                ) : (
                  ""
                )}
                <li key={name}>
                  <a
                    target="_blank"
                    href={`${link}/${lang}`}
                    className={styles.nav__link}
                    rel="noreferrer"
                  >
                    {name}
                  </a>
                </li>
              </>
            ))}
          </ul>

          <div className={styles.copyright}>
            <p>{content.copyright}</p>
            <img src={psi} alt="PSI" className={styles.copyright__img} />
          </div>
        </nav>
      </footer>
    </div>
  );
};
