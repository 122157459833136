export const routes = {
  about_us: "about-us",
  our_products: "our-products",
  why_choose_us: "why-choose-us",
  industries: "industries",
  contacts: "contacts",
};

export const routesEN = [
  {
    name: "About Us",
    path: routes.about_us,
  },
  {
    name: "Our Products",
    path: routes.our_products,
  },
  {
    name: "Why Choose Us",
    path: routes.why_choose_us,
  },
  {
    name: "Industries",
    path: routes.industries,
  },
  {
    name: "Contacts",
    path: routes.contacts,
  },
];

