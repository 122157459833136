import { FC } from "react";
import styles from "./Contacts.module.scss";

type TContacts = {
  site: {
    icon: string;
    url: string;
  };
  mail: {
    icon: string;
    emails: string[];
  };
  location: {
    icon: string;
    address: string;
  };
};

export const Contacts: FC<TContacts> = ({ site, mail, location }) => {
  return (
    <ul className={styles.list}>
      <li className={styles.item}>
        <img src={site.icon} alt="site icon" className={styles.img} />{" "}
        <a className={styles.red} target="_blank" rel="noreferrer" href={site.url}>{site.url}</a>
      </li>
      <li className={styles.item}>
        <img src={mail.icon} alt="mail icon" className={styles.img} />{" "}
        <p className={styles.emails}>
          {mail.emails.map((email) => (
            <a className={styles.red} href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
          ))}
        </p>
      </li>
      <li className={styles.item}>
        <img src={location.icon} alt="site icon" className={styles.img} />{" "}
        <span className={styles.grey}>
          {location.address.split("\n").map((text) => (
            <>
              {text}
              <br />
            </>
          ))}
        </span>
      </li>
    </ul>
  );
};
