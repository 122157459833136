import { LANG } from "../../../types/lang";
import facebook from "../../../images/footer/facebook.svg";
import linkedin from "../../../images/footer/linkedln.svg";
import instagram from "../../../images/footer/linkedln.svg";

export const dataFooter = {
  [LANG.EN]: {
    copyright: "©2024 Mandato. All Rights Reserved.",
    text: "",
    line: "Mandato Financial Services GmbH is regulated by VQF, a self-regulatory body ",
    nav: [
      {
        name: "About Us",
        link: ``,
      },
      {
        name: "Contact Us",
        link: ``,
      },
      {
        name: "Terms Of Service",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/terms-of-service`,
      },
      {
        name: "Privacy Policy",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/privacy-policy`,
      },
      {
        name: "Cookie Policy",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/cookie-policy`,
      },
    ],
    social: [
      {
        name: "Instagram",
        link: "https://www.instagram.com/mandato.global",
        icon: instagram,
      },
      {
        name: "Facebook",
        link: "https://www.facebook.com/share/URee3Xn6tZZ5EZhR/?mibextid=LQQJ4d",
        icon: facebook,
      },
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/groups/13064275",
        icon: linkedin,
      },
      
    ],
  },

  [LANG.FR]: {
    copyright: "",
    text: "",
    line: "Mandato Financial Services GmbH is regulated by VQF, a self-regulatory body ",
    nav: [
      {
        name: "About Us",
        link: ``,
      },
      {
        name: "Contact Us",
        link: ``,
      },
      {
        name: "Terms Of Service",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/terms-of-service`,
      },
      {
        name: "Privacy Policy",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/privacy-policy`,
      },
      {
        name: "Cookie Policy",
        link: `${process.env.REACT_APP_URL_GLOBAL_MANDATO}/cookie-policy`,
      },
    ],
    social: [
      {
        name: "Instagram",
        link: "https://www.instagram.com/mandato.global",
        icon: instagram,
      },
      {
        name: "Facebook",
        link: "https://www.facebook.com/share/URee3Xn6tZZ5EZhR/?mibextid=LQQJ4d",
        icon: facebook,
      },
      {
        name: "Linkedin",
        link: "https://www.linkedin.com/groups/13064275",
        icon: linkedin,
      },
      
    ],
  },
};
